<script>
    // @ts-ignore
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
const Pakets = require('./../../../api/pakets');
import DatePicker from "vue2-datepicker";
// import Swal from "sweetalert2";
/**
 * Product-checkout Component
 */

const today = new Date();
today.setHours(0, 0, 0, 0);

export default {
   page: {
    title: "Paket Tryout",
    meta: [{ name: "description", content: 'Paket Tryout on Tryout Apps' }]
  },
  components: { Layout, PageHeader,DatePicker },
  data() {
    return {
        title: "Paket Tryout",
        items: [
            {
            text: "Paket Tryout",
            active: true
            }
        ],
        Pakets : new Pakets(),
        lists : [],
        states :Pakets.states(),
        start_time : "00:00",
        end_time : "24:00",
    };
  },
  computed : {
        start_timeOption(){
            return {start:'00:00',step:'01:00',end:this.end_time}
        },
        end_timeOption(){
            return {start:this.start_time,step:'01:00',end:'24:00'}
        },
        tanggalDefault() {
            return new Date()
        },
  },
  watch : {
        states : function(newStates,old){
                console.log(old)
            if(newStates.error){
                this.$toasted.error(newStates.message, {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
            }
            if(newStates.isCreate){
                this.$bvModal.show('modal-tambah')
            }else{
                this.$bvModal.hide('modal-tambah')
                this.getList()
            }
        }
  },
  mounted(){
      this.getList()
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId == "modal-tambah"){
                this.states = this.Pakets.reset()
            }
        })
  },
  methods : {
        notBeforeToday(date) {
            return date < today;
        },
        getList(){
            this.states.loading = true;
            this.Pakets.getList(this.states)
            .then(res=>{
                this.states = res;
            })
            .catch((e)=>this.states = e)
        },
        create(){
            //   this.states = this.Pakets.reset()
            this.states = this.Pakets.showModal(this.states)
        },
        cancel(){
            this.states = this.Pakets.reset()
        },
        ok(){

        }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <button v-if="!states.isCreate" class="float btn btn-success btn-gallery" @click="create()" v-b-tooltip.hover title="Create New"><i class="bx bx-plus-circle font-size-18"></i></button>
    <div v-if="states.loading" class="row d-flex justify-content-center align-items-center align-content-center">
        <div class="d-flex justify-content-center align-items-center align-content-center">
            <i class="bx bx-loader-alt bx-spin font-size-72 text-primary"></i>
        </div>
    </div>
    <div class="row" v-else>
      <div v-for="paket in states.lists" :key="paket.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="mb-4">
              <img class="rounded-circle avatar-sm" src="https://www.flaticon.com/svg/static/icons/svg/1205/1205526.svg" alt />
            </div>
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark">{{paket.nama_paket}}</a>
            </h5>
            <p class="text-muted">Durasi : {{paket.waktu_pengerjaan}} Jam</p>
            <p class="text-muted">Active Sampai : <span class="text-danger">{{paket.tanggal_selesai}}</span></p>
            <div>
              <a
                href="javascript: void(0);"
                :class="{'badge-danger' : paket.expire,' badge-success' : !paket.expire,'badge font-size-11 m-1':true}"
              >{{`${paket.expire ? 'expire' : 'active'}`}}</a>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover.top title="Edit" href="javascript: void(0);">
                  <i class="bx bx-edit text-warning"></i>
                </a>
              </div>
              <div class="flex-fill">
                <a v-b-tooltip.hover.top title="Hapus" href="javascript: void(0);">
                  <i class="bx bx-trash text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
        <b-modal id="modal-tambah" size="md" title="Buat Paket Tryout Baru" centered no-close-on-backdrop no-close-on-esc>

                <div class="form-group">
                    <label class="control-label">Nama Paket : <span class="text-danger">*</span></label>
                    <input type="text" :class="{'form-control':true,'is-invalid': states.fields.nama_paket.error}" v-model="states.fields.nama_paket.value">
                    <div class="invalid-feedback">
                        <span>{{states.fields.nama_paket.message}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="text-muted font-size-12">From : <span class="text-red">*</span></label>
                            <date-picker  :time-picker-options="start_timeOption" format="HH:mm a" value-type="HH:mm" type="time" :placeholder="start_time" v-model="start_time" lang="en"></date-picker>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="text-muted font-size-12">To : <span class="text-red">*</span></label>
                            <date-picker  :time-picker-options="end_timeOption" format="HH:mm a" value-type="HH:mm" type="time" :placeholder="end_time" v-model="end_time" lang="en"></date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="text-muted font-size-12">Berlaku Sampai : <span class="text-red">*</span></label>
                    <date-picker  :first-day-of-week="1" :default-value="tanggalDefault"  :class="states.fields.tanggal_selesai.error ? 'is-invalid' : ''" :disabled-date="notBeforeToday" v-model="states.fields.tanggal_selesai.value" lang="en"></date-picker>
                    <div class="invalid-feedback">
                        <span>{{states.fields.tanggal_selesai.message}}</span>
                    </div>
                </div>

            <template #modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="ok()">
                    <i class="bx bxs-cloud-upload"></i> Save
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
  </Layout>
</template>
