
const Client = require('./client');
const moment = require('moment');
// const qs = require('qs')
class Pakets extends Client{
    constructor(){
        super();
        this._endpoint = 'paket';
        moment.locale('id');
    }
    static states(){
        return {
            error : false,
            loading : true,
            isCreate : false,
            message : "This value is required.",
            lists : [],
            fields : {
                id : 0,
                nama_paket: {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                waktu_pengerjaan : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                tanggal_selesai  : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
            }
        }
    }
    reset(){
        return {
            error : false,
            loading : false,
            isCreate : false,
            message : "This value is required.",
            lists : [],
            fields : {
                id : 0,
                nama_paket: {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                waktu_pengerjaan : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                tanggal_selesai  : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
            }
        }
    }
    showModal(states){
        states = this.reset();
        states.isCreate = true
        return states;
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(id,data){
        return new Promise(function(resolve,reject){
            this.put(this._endpoint+"/"+id,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpoint+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getList(states){
        return new Promise(function(resolve,reject){
            this.data()
            .then((result)=>{
                if(result.success){
                    states.error = false
                    states.loading = false
                    states.isCreate = false
                    states.message = ""
                    let lists = result.data_paket.data.map((item)=>{
                        var w = item.waktu_pengerjaan.split(' - ');
                        var startTime = moment(w[0], "HH:mm:ss a");
                        var endTime = moment(w[1], "HH:mm:ss a");
                        var duration = moment.duration(endTime.diff(startTime));
                        var hours = parseInt(duration.asHours());
                        return {
                            id : item.id,
                            nama_paket : item.nama_paket,
                            waktu_pengerjaan : hours,
                            tanggal_selesai : moment(item.tanggal_selesai).format('DD, MMMM - YYYY'),
                            expire : moment().isAfter(item.tanggal_selesai)
                        }
                    })
                    states.lists = lists
                    resolve(states)
                }else{
                    states.error = true
                    states.loading = false
                    states.isCreate = false
                    states.message = result.data_paket
                    reject(states)
                }
            })
            .catch(err=>{
                states.error = true
                states.loading = false
                states.isCreate = false
                states.message = err
                reject(states)
            });
        }.bind(this))
    }
}

module.exports = Pakets;
